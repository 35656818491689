<template>
<!-- banner -->
  <div class="relative">
    <img src="@/assets/img/home/pic_home_banner.jpg" alt="" class="home-banner ">
    <div class="absolute flex">
      <div class="flex wow fadeIn">
        <div class="left-company">
          <p class="company-sname">未来之物科技</p>
          <p class="company-name">四川未来之物科技有限责任公司</p>
        </div>
        <div class="right-project">
          <p class="right-title">公司产品</p>
          <div class="flex">
            <a class="btn store" href="/manage/merchant" target="_blank">服务商信息管理</a>
            <router-link  class="btn app" to="/app" target="_blank">帮家佳APP</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 企业介绍 -->
  <div class="relative">
    <img src="@/assets/img/home/pic_home_introduction.jpg" alt="" class="home-banner">
    <div class="absolute flex">
      <div>
        <div class="box-title active wow fadeIn">
          <p class="title-zh">企业介绍</p>
          <p class="title-en">ENTETPRISE INTRODUCTION</p>
        </div>
<!--        <p class="introduction wow fadeIn">四川未来之物科技有限责任公司，经营范围包括一般项目：技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广；数据处理服务；软件开发；数据处理和存储支持服务；工业互联网数据服务；数字文化创意软件开发；计算机软硬件及辅助设备零售；互联网设备销售；光通信设备销售；智能家庭消费设备销售</p>-->
        <p class="introduction wow fadeIn">
          <span>四川未来之物科技有限责任公司，专注行业系统整体解决方案，以科技 数字化为工具，推动行业数字化建设。<br/><br/></span>
          <span>帮家佳平台，是四川未来之物科技有限责任公司专注于家庭服务行业，为服务企业研发的一站式管理平台，旨在成为家庭服务行业信息化平台标杆。
            帮家佳借助数字化，为服务企业提供快捷、有效的用户互动渠道，帮助家庭服务企业提升服务企业经营效率、变革服务经营方式，从而助力家庭服务行业发展，推进行业信息化、规范化、标准化建设。</span>
        </p>
        <div class="contact wow fadeIn">联系我们</div>
        <div class="technology wow fadeIn">科技/TECHNOLOGY</div>
      </div>
    </div>
  </div>
  <!-- 科技实力 -->
  <div class="relative">
    <img src="@/assets/img/home/pic_home_technology.jpg" alt="" class="home-banner">
    <div class="absolute flex">
      <div>
        <div class="box-title wow fadeIn">
          <p class="title-zh">强大科技实力</p>
          <p class="title-en">SCIENTIFIC AND TECHNOLOGICAL STRENGTH</p>
        </div>
        <div class="flex">
          <div v-for="(item,index) in technologyList" class="technologyList-item wow fadeIn" :key="index">
            <img :src="item.img" alt="">
            <p>{{item.label}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 联系我们 -->
  <div class="flex contact-us-container ">
    <div class="content-width">
      <div class="box-title active wow fadeIn">
        <p class="title-zh">联系我们</p>
        <p class="title-en">CONTACT US</p>
      </div>
      <div class="flex">
        <img src="@/assets/img/home/pic_home_contact.jpg" alt="" class="contact-map">
        <div>
          <div class="contact-box wow fadeIn">
            <p class="contact-title">联系地址：</p>
            <p class="contact-value">四川省成都市金牛区北三环路一段221号</p>
          </div>
          <div class="contact-box wow fadeIn">
            <p class="contact-title">联系人：</p>
            <p class="contact-value">陈经理</p>
          </div>
          <div class="contact-box wow fadeIn">
            <p class="contact-title">联系电话：</p>
            <p class="contact-value">18311993299</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pic_home_technology_1 from '@/assets/img/home/pic_home_technology_1.png'
import pic_home_technology_2 from '@/assets/img/home/pic_home_technology_2.png'
import pic_home_technology_3 from '@/assets/img/home/pic_home_technology_3.png'
import pic_home_technology_4 from '@/assets/img/home/pic_home_technology_4.png'
export default{
  data() {
    return {
      technologyList:[{
        img:pic_home_technology_1,
        label:'软件研发'
      },{
        img:pic_home_technology_2,
        label:'硬件研发'
      },{
        img:pic_home_technology_3,
        label:'大数据系统'
      },{
        img:pic_home_technology_4,
        label:'物联网研发'
      }]
    }
  }
}
</script>

<style lang="scss" scoped>
.home-banner{
  width: 100%;
}
.absolute{
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  align-items: center;
  color: white;
  justify-content: center;
}
.company-sname{
  font-size: 100px;
  font-family: PingFang-SC-Heavy, PingFang-SC;
  font-weight: 800;
  color: #FFFFFF;
  line-height: 140px;
}
.company-name{
  font-size: 30px;
  font-family: PingFang-SC-Heavy, PingFang-SC;
  font-weight: 800;
  color: #FFFFFF;
  line-height: 42px;
  text-align: right;
}
.left-company{
  padding-right: 53px;
  border-right: 1px solid white;
}
.right-project{
  padding-left: 59px;
}
.right-title{
  font-size: 30px;
  font-family: PingFang-SC-Heavy, PingFang-SC;
  font-weight: 800;
  color: #FFFFFF;
  line-height: 42px;
  margin-bottom: 20px;
}
.btn{
  width: 232px;
  line-height: 96px;
  border-radius: 5px;
  font-size: 30px;
  font-weight: 800;
  display: inline-block;
  color: #FFFFFF;
  text-decoration: none;
  text-align: center;
  &.store{
    background: $theme;
  }
  &.app{
    border: 2px solid #FFFFFF;
    margin-left: 30px;
  }
}
.box-title{
  text-align: center;
  &.active{
    color: $theme;
  }
  .title-zh{
    font-size: 50px;
    font-family: PingFang-SC-Heavy, PingFang-SC;
    font-weight: 800;
    line-height: 70px;
  }
  .title-en{
    font-size: 16px;
    font-family: PingFang-SC-Heavy, PingFang-SC;
    font-weight: 800;
    line-height: 22px;
  }
}
.introduction{
  font-size: 20px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #666666;
  line-height: 28px;
  width: 766px;
  margin: 36px auto;
  text-align: left;
  span{
    padding-left: 24px;
  }
}
.contact{
  width: 134px;
  line-height: 46px;
  border-radius: 5px;
  border: 2px solid $theme;
  text-align: center;
  color: $theme;
  font-size: 20px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  margin: 0 auto;
}
.technology{
  width: 1200px;
  height: 25px;
  font-size: 18px;
  font-family: PingFang-SC-Heavy, PingFang-SC;
  font-weight: 800;
  color: #A1A1A1;
  line-height: 25px;
  letter-spacing: 78px;
  text-align: center;
  margin-top: 122px;
}
.technologyList-item{
  text-align: center;
  padding: 0 27px;
  margin-top: 72px;
  img{
    display: block;
    width: 270px;
    height: 200px;
  }
  p{
    font-size: 30px;
    font-family: PingFang-SC-Heavy, PingFang-SC;
    font-weight: 800;
    color: #FFFFFF;
    line-height: 42px;
    margin-top: 22px;
  }
}
.contact-box{
  margin-bottom: 28px;
  &:first-child{
    margin-top: 14px;
  }
  .contact-title{
    font-size: 16px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #999999;
    line-height: 22px;
  }
  .contact-value{
    font-size: 25px;
    font-family: PingFang-SC-Heavy, PingFang-SC;
    font-weight: 800;
    color: #333333;
    line-height: 34px;
    margin-top: 6px;
  }
}
.contact-map{
  width: 689px;
  height: 292px;
  margin-right: 64px;
}
.contact-us-container{
  .flex{
    margin-top: 50px;
  }
  padding: 105px 0 150px;
  background: #FAFBFC;
}
</style>
