<template>
  <div class="footer">
    <!-- 第一行 -->
    <div class="link-box">
      <router-link to="/UsersManual" target="_blank">用户协议</router-link> ｜
      <router-link to="/PrivacyPolicy" target="_blank">隐私政策</router-link>
    </div>
    <!-- 第二行 -->
    <div>©2021-2024 bangjiajia.net 版权所有 ｜ <a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备2022006464号-3 </a>
      ｜ 增值电信业务经营许可证(国内多方通信服务业务)编号:B2-20233967
    </div>
    <!-- 第三行 -->
    <div class="jh-box">
      <div class="jh-content">
        <a
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?spm=5176.19720258.J_9220772140.109.e9392c4arQFOxF"
        ><img src="~@/assets/img/jh.png" style="float: left"
        /></a>
        <span class="jh-text">川公网安备 51010602001995号</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.footer {
  // padding-top: 140px;
  font-size: 18px;
  background: #ffffff;
  text-align: center;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #939393;
}
.link-box {
  padding: 40px 0 20px;
  a{
    color:#939393;
    &:hover{
      text-decoration: underline;
    }
  }
}
.jh-box {
  padding: 20px 0;
}
.jh-content {
  display: inline-block;
  text-decoration: none;
  height: 20px;
  line-height: 20px;
  .jh-text {
    float: left;
    height: 20px;
    line-height: 20px;
    margin: 0px 0px 0px 5px;
    color: #939393;
  }
}
</style>
