<template>
  <div class="header" :class="{'active':isActive,'txt-active':txtActive}">
    <div class="content-width flex flex-between">
      <div class="router">
        <router-link  :to="item.url" v-for="(item,index) in routerLink" :key="index">{{item.label}}</router-link>
      </div>
      <div class="phone">
        18311993299
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    txtActive:{
      type:Boolean,
      defalut:false
    }
  },
  data() {
    return {
      // routerLink:[{
      //   label:'首页',
      //   url:"/"
      // },{
      //   label:'企业管理',
      //   url:"/manager"
      // },{
      //   label:'客户端APP',
      //   url:"/app"
      // }],
      routerLink:[{
        label:'首页',
        url:"/home"
      },{
        label:'客户端APP',
        url:"/app"
      },{
        label:'关于我们',
        url:"/manager"
      }],
      isActive:false
    }
  },
  mounted(){
    this.$nextTick(()=>{
       window.addEventListener('scroll',()=>{
         const top = window.pageXOffset || document.documentElement.scrollTop || document.body.scrollTop
         this.isActive = top>50
       })
    })
  }
}
</script>

<style lang="scss" scoped>
.header{
  height: 100px;
  font-size: 16px;
  color: #333;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  background: transparent;
  transition: all 0.3s;
  z-index: 55;
  .router{
    a{
      text-decoration: none;
      font-size: 16px;
      color: #333;
      margin-right: 76px;
      position: relative;
      padding-bottom: 6px;
      &.router-link-active{
        &::after{
          content: '';
          position: absolute;
          border-radius: 5px;
          bottom: 0;
          left: 50%;
          margin-left: -12px;
          width: 24px;
          height: 3px;
          background: $theme;
        }
      }
    }
  }
  &.txt-active{
    .phone{
      color: #fff;
    }
    a{
      color: #fff;
      &.router-link-active{
        color:#fff;
        &::after{
          background: #fff;
        }
      }
    }
  }
  &.active{
    background: rgba($color:white, $alpha: 0.95);

    .phone{
      color: #333;
    }
    a{
      color: #333;
      &.router-link-active{
        color:$theme;
        &::after{
          background: $theme;
        }
      }
    }
  }
}
.content-width{
  padding: 0 20px;
  height: 100%;
  align-items: center;
}
// .router{
//   a{
//     text-decoration: none;
//     font-size: 16px;
//     color: white;
//     margin-right: 76px;
//     position: relative;
//     padding-bottom: 6px;
//     &.router-link-active{
//       color: $theme;
//       &::after{
//         content: '';
//         position: absolute;
//         border-radius: 5px;
//         bottom: 0;
//         left: 50%;
//         margin-left: -12px;
//         width: 24px;
//         height: 3px;
//         background: $theme;
//       }
//     }
//   }
// }
</style>
