<template>
  <div class="relative top-box">
    <img src="@/assets/img/manager/pic_administration_banner.jpg" alt="" class="home-banner ">
    <div class="absolute">
      <p class="tag">服务企业管理</p>
      <div class="propaganda-txt">
        <p>让家庭服务</p>
        <p>井然有序</p>
      </div>
      <a href="/manage/merchant" target="_blank" class="login-btn">服务公司登录</a>
      <div class="flex">
        <div v-for="(item,index) in bannerTxtList" :key="index" class="bannerList-box">
          <div>
            <p>{{ item.label1 }}</p>
            <p>{{ item.label2 }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="register-box" :class="{'isClose':isClose,'hide':ishide}">
    <div class="content-width flex">
<!--      <a href="/manage/merchant" target="_blank" class="register-btn">-->
<!--        立即注册-->
<!--      </a>-->
      <div class="center-txt">让您的企业“井然有序”</div>
      <div>
        <img src="@/assets/img/manager/close.png" alt="" class="close" @click="close">
      </div>
    </div>
  </div>
  <div class="flex administration-box">
    <div class="flex-1">
      <img src="@/assets/img/manager/pic_administration_administration.png" alt="" class="administration">
    </div>
    <div class="flex-1 administration-right">
      <div class="propaganda-txt">
        <p>管理IT化</p>
        <p>让您的企业 井然有序</p>
      </div>
      <div class="flex administrationList-box">
        <div v-for="(item, index) in administrationList" :key="index" class="flex">
          <div class="index">{{ item.index }}</div>
          <div class="flex-1">
            <p class="title">{{ item.title }}</p>
            <p class="label">{{ item.label }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="relative order">
    <img src="@/assets/img/manager/pic_administration_order.jpg" alt="" class="home-banner">
    <div class="absolute">
      <!--      <p class="order-manager wow fadeIn">订单管理 • 井然有序</p>-->
      <p class="order-manager wow fadeIn">订单管理 • 科技赋能</p>
      <p class="box-tag wow fadeIn">服务企业管理系统</p>
      <div class="flex order-step-container">
        <div v-for="(item,index) in orderList" :key="index" class="order-step-box flex wow fadeIn">
          <div>
            <div class="flex">
              <p class="index">{{ item.index }}</p>
              <p class="title">{{ item.title }}</p>
            </div>
            <p class="label">{{ item.label }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="relative staff">
    <img src="@/assets/img/manager/pic_administration_staff.jpg" alt="" class="home-banner">
    <div class="absolute right">
      <div class="box-title wow fadeIn">
        <p>
          员工管理
        </p>
        <!--        <p>井然有序</p>-->
        <p>高效便捷</p>
      </div>
      <div v-for="(item, index) in managerRight" :key="index" class="wow fadeIn">
        <div class="index flex">
          <span>{{ index + 1 }}</span>
          <p class="title">{{ item.title }}</p>
        </div>
        <div class="label">{{ item.value }}</div>
      </div>
    </div>
  </div>
  <div class="relative staff">
    <img src="@/assets/img/manager/pic_administration_customer.jpg" alt="" class="home-banner">
    <div class="absolute left">

      <div class="box-title wow fadeIn">
        <p>
          客户管理
        </p>
        <p>智能支持</p>
      </div>
      <div v-for="(item, index) in managerLeft" :key="index" class="wow fadeIn">
        <div class="index flex">
          <span>{{ index + 1 }}</span>
          <p class="title">{{ item.title }}</p>
        </div>
        <div class="label">{{ item.value }}</div>
      </div>
    </div>
  </div>
  <div class="relative risk">
    <img src="@/assets/img/manager/pic_administration_risk.jpg" alt="" class="home-banner">
    <div class="absolute right">
      <div class="box-title wow fadeIn">
        <p>
          风控管理
        </p>
        <!--        <p>井然有序</p>-->
        <p>安全保障</p>
      </div>
      <div v-for="(item, index) in riskLeft" :key="index" class="wow fadeIn">
        <div class="flex">
          <span class="index">{{ index + 1 }}</span>
          <div>
            <p class="title">{{ item.title }}</p>
            <div class="label">{{ item.value }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="relative">
    <img src="@/assets/img/manager/pic_administration_operate.jpg" alt="" class="home-banner">
    <div class="absolute operate">
      <div class="box-title wow fadeIn">
        <p class="order-manager">运营管控 • 多维有效</p>
        <div class="tag">服务企业管理系统</div>
      </div>
      <div class="flex content-width">
        <div v-for="(item,index) in operateList" class="operateList-item wow fadeIn" :key="index">
          <img :src="item.img" alt="">
          <p class="title">{{ item.title }}</p>
          <p class="label">{{ item.label }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pic_administration_operate_1 from '@/assets/img/manager/pic_administration_operate_1.png'
import pic_administration_operate_2 from '@/assets/img/manager/pic_administration_operate_2.png'
import pic_administration_operate_3 from '@/assets/img/manager/pic_administration_operate_3.png'

export default {
  data() {
    return {
      bannerTxtList: [{
        label1: "服务",
        label2: "管理"
      }, {
        label1: "订单",
        label2: "管理"
      }, {
        label1: "客户",
        label2: "管理"
      }],
      administrationList: [{
        title: '服务管理',
        label: '在线服务管理，让客户快捷查看，高效管理',
        index: '01'
      },
        //   {
        //   title:'员工管理',
        //   label:'查看员工信息，员工业绩，员工状态，掌握公司运作动态',
        //   index:'05'
        // },
        {
          title: '价格历史',
          label: '价格修改记录，价格变更透明，方便跟踪统计',
          index: '02'
        }, {
          title: '订单跟踪',
          label: '订单状态跟踪，把控工作进展，推动工作效率',
          index: '03'
        }, {
          title: '客户管理',
          label: '管理客户信息，挖掘优质客户，告别纸质管理',
          index: '04'
        }, {
          title: '广告分发',
          label: '自定义广告，引导客户消费，树立品牌形象',
          index: '05'
        }, {
          title: '数据统计',
          label: '查看订单统计，查看服务统计，分析公司业绩，分析人员业绩，告别手动计算',
          index: '06'
        }
        // ,
        //   {
        //   title:'定制功能',
        //   label:'注册后使用，可根据服务商运营特色定制研发相关功能',
        //   index:'08'
        // }
      ],
      orderList: [{
        label: '快速找到需要的服务，下单操作更简单',
        title: '添加订单',
        index: '01'
      }, {
        label: '快速回应客户订单，高效分配订单',
        title: '接受订单',
        index: '02'
      }, {
        label: '精确筛选时间段空闲服务人员，准确派单',
        title: '服务派单',
        index: '03'
      }, {
        label: '手动开启服务，掌握员工状态',
        title: '开始服务',
        index: '04'
      }, {
        label: '客户手动完成服务，确保服务质量',
        title: '完成订单',
        index: '05'
      }, {
        // label: '在线记录每次价格变动，可追溯、归档、统计、分析',
        label: '订单事件记录，查看订单状态，把控工作进展',
        title: '流程跟踪',
        index: '06'
      }

      ],
      managerRight: [{
        title: '工作状态管理',
        value: '查看员工信息、查看员工状态、查看员工服务订单状态'
      }, {
        title: '处理派单信息',
        // value: '员工在手机自己服务哪些订单，订单服务地址、联系人信息，订单服务状态',
        value: '查看派单信息、一键开启订单服务，合理分配工作时间'
      }, {
        title: '员工业绩统计',
        // value: '员工业绩统计员工业绩数据、服务和订单相关数据全部由系统统计，打掉人工统计环节，准确高效'
        value: '统计服务业绩，改善员工工作，促进业务发展，促进企业进步'
      }],
      managerLeft: [{
        title: '客户在线沟通',
        value: '客户订单、订单服务进展清晰明了，客户沟通方便高效'
      }, {
        title: '长期用户推广',
        value: '长期用户活动，长期用户套餐，轻松稳定客户，助力企业长久稳定'
      }, {
        title: '优质客户挖掘',
        value: '消费数据、服务数据统计，发掘高频消费、优质潜客户，助力企业业务扩展'
      }],
      riskLeft: [{
        title: '签约规范',
        value: '明确客户责任，维护机构、服务人员、消费者三方权益'
      }, {
        title: '流程规范',
        // value: '系统提醒客户检查验收，客户自己检查家具水电气，员工服务完成即走'
        value: '标准化订单处理流程，客户现场验收评价'
      }, {
        title: '管理规范',
        // value: '订单无遗漏，准时准点到达服务地址，避免漏单，走错单，客户投诉',
        value: '避免订单遗漏，处理客户评价'
      }],
      operateList: [{
        img: pic_administration_operate_1,
        title: '广告分发',
        label: '自定义App首页广告，高效推广企业和活动'
      }, {
        img: pic_administration_operate_2,
        title: '数据分析',
        label: '服务数据统计、订单数据统计、员工数据统计、客户数据统计'
      }, {
        img: pic_administration_operate_3,
        title: '运营分析',
        label: '时刻掌握运营状态，及时调整运营策略'
      }],
      isClose: false,
      ishide: false
    }
  },
  methods: {
    close() {
      if (this.isClose) {
        this.ishide = true;
        // sessionStorage.setItem('isHide',true)
      }
      this.isClose = true;
    }
  }
}
</script>
<style lang="scss" scoped>

.top-box {
  .absolute {
    top: 231px;
    width: 1200px;
    left: 50%;
    margin-left: -600px;
  }

  .tag {
    width: 195px;
    line-height: 47px;
    border-radius: 5px;
    opacity: 0.4;
    font-size: 20px;
    font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
    font-weight: bold;
    color: #303133;
    border: 3px solid #303133;
    text-align: center;
  }
}

.propaganda-txt {
  p {
    font-size: 80px;
    font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
    font-weight: bold;
    color: $theme;
    line-height: 101px;
    letter-spacing: 5px;
    text-shadow: 10px 10px 30px rgba(48, 49, 51, 0.05);
  }

  margin: 20px 0 28px;
}

.login-btn {
  text-decoration: none;
  display: block;
  width: 292px;
  line-height: 76px;
  text-align: center;
  background: $theme;
  box-shadow: 10px 10px 30px 0px rgba(0, 40, 121, 0.1);
  border-radius: 5px;
  font-size: 26px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #FFFFFF;
}

.bannerList-box {
  width: 100px;
  height: 100px;
  box-shadow: 10px 10px 30px 0px rgba(34, 40, 52, 0.05);
  opacity: 0.4;
  border: 3px solid #303133;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  margin-top: 48px;
  margin-right: 25px;

  p {
    &:first-child {
      margin-bottom: 5px;
    }
  }
}

.register-box {
  height: 152px;
  background: rgba(55, 97, 173, 0.9);

  .content-width {
    height: 100%;
    align-items: center;
  }

  .register-btn {
    display: block;
    width: 287px;
    height: 92px;
    line-height: 92px;
    border-radius: 5px;
    border: 4px solid #FFFFFF;
    font-size: 40px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    text-align: center;
    color: #FFFFFF;

  }

  .center-txt {
    font-size: 44px;
    font-family: PingFang-SC-Heavy, PingFang-SC;
    font-weight: 800;
    color: #FFFFFF;
    flex: 1;
    text-align: center;
  }

  .close {
    width: 92px;
    height: 92px;
    cursor: pointer;
  }
}

.administration-box {
  align-items: center;
  justify-content: center;
  width: 1200px;
  margin: 122px auto 0;
  padding: 61px 0 61px 0;
}

.administration {
  width: 666px;
}

.administration-right {
  margin-bottom: 136px;

  .propaganda-txt {
    p {
      font-size: 50px;
      line-height: 64px;
    }

  }
}

.administrationList-box {
  margin-top: 6px;
  flex-wrap: wrap;
  width: 684px;

  .flex {
    width: 278px;
    margin-top: 56px;
    margin-right: 64px;
  }

  .index {
    width: 26px;
    height: 26px;
    flex: 0 0 26px;
    border-radius: 5px;
    border: 2px solid #303133;
    text-align: center;
    line-height: 26px;
    font-size: 15px;
    font-family: PingFang-SC-Heavy, PingFang-SC;
    font-weight: 800;
    color: #333333;
    margin-right: 10px;
  }

  .title {
    font-size: 30px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #333333;
  }

  .label {
    font-size: 16px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #999999;
    line-height: 22px;
    margin-top: 6px;
    width: 278px;
  }
}

.order-manager {
  font-size: 80px;
  font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 101px;
  text-align: center;
}

.box-tag {
  width: 195px;
  line-height: 47px;
  border-radius: 5px;
  border: 3px solid #FFFFFF;
  font-size: 20px;
  font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
  font-weight: bold;
  color: #FFFFFF;
  text-align: center;
  margin: 21px auto 68px;
}

.order-step-container {
  flex-wrap: wrap;
  width: 1200px;
  margin: 0 auto;
}

.order-step-box {
  width: 350px;
  height: 173px;
  border-radius: 5px;
  border: 3px solid #FFFFFF;
  align-items: center;
  justify-content: center;
  margin-bottom: 79px;

  &:not(:nth-child(3n)) {
    margin-right: 65px;
  }

  .index {
    width: 32px;
    height: 32px;
    border-radius: 5px;
    border: 3px solid #FFFFFF;
    text-align: center;
    line-height: 32px;
    font-size: 15px;
    font-family: PingFang-SC-Heavy, PingFang-SC;
    font-weight: 800;
    color: white;
    margin-right: 10px;
  }

  .title {
    font-size: 36px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #FFFFFF;
  }

  .label {
    width: 270px;
    font-size: 16px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 22px;
    margin-top: 10px;
  }
}

.home-banner {
  width: 100%;
}

.order {
  .absolute {
    left: 0;
    top: 134px;
    width: 100%;
  }
}

.risk {
  .absolute {
    width: 50%;
    height: 100%;
    background: linear-gradient(180deg, #F5F4F9 0%, #EAE9EE 100%);
    top: 0;
    padding-top: 184px;
    color: white;
    box-sizing: border-box;
    right: 0;
    padding-left: 97px;

    .box-title {
      margin-bottom: 40px;

      p {
        font-size: 80px;
        font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
        font-weight: bold;
        color: #3761AD;
        line-height: 101px;
      }
    }

    .index {
      width: 26px;
      height: 26px;
      flex: 0 0 26px;
      border-radius: 5px;
      border: 2px solid #303133;
      text-align: center;
      line-height: 26px;
      font-size: 15px;
      font-family: PingFang-SC-Heavy, PingFang-SC;
      font-weight: 800;
      color: #333333;
      margin-right: 10px;
    }

    .title {
      font-size: 30px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #333333;
    }

    .label {
      font-size: 16px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #999999;
      line-height: 22px;
      margin-top: 6px;
      width: 278px;
      margin-bottom: 39px;
    }
  }
}

.staff {
  .absolute {
    width: 50%;
    height: 100%;
    background: rgba(8, 13, 23, 0.4);
    top: 0;
    padding-top: 105px;
    color: white;
    box-sizing: border-box;

    .box-title {
      margin-bottom: 40px;

      p {
        font-size: 80px;
        font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 101px;
        letter-spacing: 3px;
      }
    }

    .index {
      width: 287px;
      height: 68px;
      border-radius: 5px;
      border: 3px solid #FFFFFF;
      line-height: 68px;

      span {
        width: 67px;
        height: 70px;
        background: white;
        color: #000000;
        font-size: 30px;
        text-align: center;
        line-height: 68px;
        position: relative;
        left: -1px;
        top: -1px;
      }

      p {
        font-size: 30px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 68px;
        text-align: center;
        flex: 1;
      }
    }

    .label {
      width: 395px;
      margin-top: 16px;
      font-size: 16px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 22px;
      margin-bottom: 47px;
    }

    &.right {
      right: 0;
      padding-left: 97px;
    }

    &.left {
      left: 0;
      padding-right: 97px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      text-align: right;

      .index {
        display: inline-flex;
      }
    }
  }
}

img {
  display: block;
}

.operate {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  box-sizing: border-box;
  padding-top: 164px;
  text-align: center;
  right: 0;
  color: white;

  .tag {
    width: 195px;
    height: 47px;
    border-radius: 5px;
    border: 3px solid #FFFFFF;
    text-align: center;
    font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 47px;
    margin: 22px auto 0;
  }

  .flex {
    justify-content: space-between;
    margin-top: 70px;
  }

  img {
    width: 320px;
    height: 240px;
    border-radius: 5px;
    margin: 0 auto;
  }

  .title {
    font-size: 30px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 42px;
    margin-top: 27px;
    margin-bottom: 9px;
  }

  .label {
    width: 320px;
    font-size: 16px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 22px;
  }
}

@media (max-width: 1780px) {
  .order .absolute {
    top: 80px;
  }
  .staff .absolute {
    padding-top: 80px;
  }
  .box-title {
    p {
      font-size: 40px !important;
      line-height: 50px !important;
    }
  }
  .top-box .absolute {
    top: 120px;
  }
  .order-step-box {
    height: 150px;
    width: 330px;
    margin-bottom: 50px;
  }
  .box-tag {
    margin-bottom: 40px;
  }
  .staff .label {
    margin-bottom: 30px !important;
  }
  .operate {
    padding-top: 100px;
  }
}

.isClose {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 55;
}

.hide {
  display: none;
}
</style>
