import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '@/pages/Home' //关于我们
import Manager from '@/pages/Manager' // 首页
import App from '@/pages/App' //客户端app
import UsersManual from '@/pages/UsersManual' //用户协议
import PrivacyPolicy from '@/pages/PrivacyPolicy' //隐私政策

/* Layout */
import Layout from '@/layout'

const routes = [
  {
    path: '/', 
    component: Layout,
    redirect: '/home',
    children: [{
      path: '/home',
      component: Manager,
    },{
      path: '/manager',
      component: Home,
    },{
      path: '/app',
      component: App,
    }]
  },
  {
    path: '/usersManual',
    component: UsersManual
  },
  {
    path: '/privacyPolicy',
    component: PrivacyPolicy
  },
]
const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior: () => ({ y: 0 }),
  routes,
})
export default router