<template>
  <div class="app-wrapper">
    <website-header :txtActive="txtActive"></website-header>
    <div class="flex-1 content">
      <router-view></router-view>
    </div>
    <website-footer></website-footer>
  </div>
</template>

<script>
import websiteHeader from "../components/Header";
import websiteFooter from "../components/Footer";
import { WOW } from "wowjs";

export default {
  name: "Layout",
  components: {
    websiteHeader,
    websiteFooter,
  },
  data() {
    return {};
  },
    watch: {
    $route() {
      document.documentElement.scrollTop = 0;
      this.$nextTick(() => {
        new WOW({ mobile: true, live: true }).init();
      });
    },
  },
  computed: {
    txtActive() {
      return this.$route.path === "/manager";
    },
  },
  methods: {},
};
</script>
