import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'wowjs/css/libs/animate.css';
const view = createApp(App)
view.use(router)
// router.beforeEach((to,form,next)=>{
//   document.body.scrollTop = 0;
//   document.documentElement.scrollTop = 0;
//   window.pageYOffset = 0;
//   next()
// })
view.mount('#app')
