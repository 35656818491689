<template>
  <!-- <website-header :txtActive="txtActive"></website-header>
  <div class="flex-1 content">
    <router-view></router-view>
  </div>
  <website-footer></website-footer> -->
  <router-view></router-view>
</template>

<script>
export default {
  name: "App"
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  line-height: 1;
  font-family: PingFang-SC-Heavy, PingFang-SC;
  font-size: 16px;
}
a {
  text-decoration: none;
}
@media screen and (min-width: 769px) {
  #app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-width: 1400px;
  }
}

</style>
