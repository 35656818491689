<template>
  <div class="relative header-banner">
    <img src="@/assets/img/app/pic_app_banner.jpg" alt="" class="banner">
    <div class="header-content">
      <div class="content-width">
        <div class="flex">
          <div>
            <div class="banner-title wow fadeIn">
              <p>一站式家庭服务</p>
              <p>品类齐全</p>
            </div>
            <div class="flex">
              <div class=" wow fadeIn">
                <div class="qrcode"><img src="~@/assets/img/iphone_qrcode.png"/></div>
                <p class="app-download">苹果手机下载</p>
              </div>
              <div class="android  wow fadeIn">
                <div class="qrcode"><img src="~@/assets/img/android_qrcode.png"/></div>
                <p class="app-download">安卓手机下载</p>
              </div>
            </div>
          </div>
          <div class=" wow fadeIn">
            <img src="@/assets/img/app/pic_app_phone.png" alt="" class="app-phone ">
          </div>
        </div>
      </div>
      <div class="field">
        <div class="content-width flex">
          <div v-for="(item,index) in fieldList" :key="index">
            <p class="fieldList-title  wow fadeIn">{{item.title}}</p>
            <div class="wow fadeIn">
              <ul>
                <li v-for="(val,i) in item.list" :key="i">
                  {{val}}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="relative">
    <img src="@/assets/img/app/pic_app_quick.jpg" alt="" class="home-banner ">
    <div class="absolute center-box">
      <div class="content-width right">
        <div>
          <p class="title  wow fadeIn">服务快捷</p>
          <p  class="label wow fadeIn">快捷联系服务商，高效沟通，服务准时</p>
        </div>
      </div>
    </div>
  </div>
  <div class="relative">
    <img src="@/assets/img/app/pic_app_easy.jpg" alt="" class="home-banner ">
    <div class="absolute center-box">
      <div class="content-width left">
        <div>
          <p class="title wow fadeIn">服务放心</p>
          <p  class="label wow fadeIn">优选服务商，服务有品质</p>
        </div>
      </div>
    </div>
  </div>
  <div class="relative">
    <img src="@/assets/img/app/pic_app_guarantee.jpg" alt="" class="home-banner ">
    <div class="absolute center-box">
      <div class="content-width right">
        <div>
          <p class="title wow fadeIn">服务保障</p>
          <p  class="label wow fadeIn">完善监督体系，保障您的权益</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fieldList:[{
        title:'保洁清洗',
        list:['家庭保洁','家电清洗','地毯清洗','水管地暖清洗']
      },{
        title:'家居美化',
        list:['地板打蜡','瓷砖美缝','石材保养','地板清洗']
      },{
        title:'日常疏通',
        list:['开/换锁','管道疏通','汽车开锁','水池疏通']
      },{
        title:'健康管理',
        list:['家庭医生','空气治理','甲醛检测','医生上门']
      },{
        title:'保姆养护',
        list:['月嫂','育儿嫂','家庭保护','养老看护']
      }]
    }
  },
}
</script>

<style lang="scss" scoped>
.absolute{
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.banner{
  display: none;
}
@media (min-width: 1921px) {
  .header-banner{
    background: white !important;
    height:auto !important ;
    padding-top: 0!important;
    .header-content{
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
  .banner{
    display: block;
  }
}
.center-box{
  .content-width{
    height: 100%;
    display: flex;
    align-items: center;
    color: white;
  }

  .right{
    text-align: right;
    justify-content: flex-end;
  }
  .title{
    font-size: 80px;
    font-family: PingFang-SC-Heavy, PingFang-SC;
    font-weight: 800;
    color: #FFFFFF;
    line-height: 112px;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  }
  .label{
    font-size: 50px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 70px;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  }
}
img{
  display: block;
}
.header-banner{
  background: url(~@/assets/img/app/pic_app_banner.jpg) no-repeat;
  background-size: 1920px 100%;
  background-position: center;
  height:913px ;
  color: white;
  padding-top: 113px;
  box-sizing: border-box;
  .banner-title{
    margin-top: 14px;
    margin-bottom: 36px;
    p{
      font-size: 80px;
      font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 101px;
    }
  }
}
.app-phone{
  height: 474px;
  margin-left:79px;
  position: relative;
  bottom: -35px;
}
.qrcode{
  width: 133px;
  height: 133px;
  padding: 5px;
  background: white;
}
.app-download{
  font-size: 20px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 28px;
  margin-top: 4px;
  text-align: center;
}
.android{
  margin-left: 20px;
}
.field{
  height: 327px;
  background: rgba($color: $theme, $alpha: 0.4);
  overflow: hidden;
  .content-width{
    justify-content: space-between;
    margin-top: 60px;
    ul{
      padding-left: 14px;
    }
    li{
      margin-top: 13px;
      font-size: 16px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 22px;
    }
  }
}
img{
  width: 100%;
}
.fieldList-title{
  width: 158px;
  height: 57px;
  border-radius: 5px;
  border: 2px solid #FFFFFF;
  font-size: 32px;
  text-align: center;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 57px;
}
</style>
